import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const AboutPage = () => (
  <Layout>
    <h1>Hi from the about page</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus mollis sodales velit in
      egestas. Nulla vel convallis urna, id dictum arcu. Nunc nec molestie enim. Vestibulum placerat
      et odio sed elementum. Etiam tempus magna luctus vestibulum suscipit. Duis quis tortor in
      tellus efficitur facilisis. Class aptent taciti sociosqu ad litora torquent per conubia
      nostra, per inceptos himenaeos. Morbi congue ex ut ante mollis placerat. Ut vel scelerisque
      nisl. Pellentesque quis eros nec ex sagittis rhoncus.
    </p>
    <p>
      Aenean vitae pellentesque lorem. Nunc sed libero vestibulum, facilisis enim quis, ultrices
      diam. Vivamus sit amet erat metus. Orci varius natoque penatibus et magnis dis parturient
      montes, nascetur ridiculus mus. Donec convallis dolor massa, eget convallis quam suscipit
      vitae. Sed tortor nibh, tincidunt non libero non, tincidunt consectetur metus. Duis in posuere
      sapien, a eleifend nulla.
    </p>
    <p>
      Integer ac luctus mi. Vestibulum quis sapien pulvinar, tincidunt diam ut, blandit ipsum. Nunc
      laoreet tincidunt neque, luctus blandit purus tincidunt ac. Nullam porttitor, ex quis vehicula
      tincidunt, enim tellus vestibulum mauris, id porttitor sapien justo quis mauris. In interdum,
      dui sit amet dignissim tincidunt, libero sem commodo nisi, quis tristique urna ligula quis
      tortor. Donec sed nulla ornare, rhoncus lorem finibus, condimentum elit. Fusce mollis, ipsum
      ut lacinia elementum, turpis ante scelerisque eros, eget malesuada nibh tellus quis mauris.
      Curabitur rhoncus sagittis mauris eget vulputate. Aliquam eget metus nunc. Praesent ex tellus,
      placerat ullamcorper porttitor ut, sagittis eget metus. Nunc placerat sollicitudin risus, et
      scelerisque ligula ultrices eu. Aliquam ultrices dui ac ullamcorper ultrices. Nulla quis justo
      non orci imperdiet tempor. Fusce ac massa ultricies, tempor est non, vestibulum enim. Donec
      fermentum sapien enim, vel elementum quam hendrerit ut. Pellentesque eget ipsum et risus
      vehicula vestibulum.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default AboutPage;
